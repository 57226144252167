import * as Yup from 'yup'

export interface SpecialtyRequest {
    success: boolean
    message: string
    data: {
        select: {
            id: number
            nm_specialty: string
        }[]
    }
}

export interface SubSpecialtyRequest {
    success: boolean
    message: string
    data: {
        select: {
            id: number
            nm_subspecialty: string
        }[]
    }
}

export interface TypeClinicRequest {
    success: boolean
    message: string
    data: {
        select: {
            id: number
            nm_type_clinic: string
        }[]
    }
}

export const initialValues = {
    nm_clinic: '',
    ds_headquarters_or_branch: '',
    cd_cnpj: '',
    type_clinic_id: '',
    specialties: [],
    subspecialties: [],
    ds_business_segment: '',

    nm_site: '',
    cd_phone_number: '',
    ds_administrative_email: '',
    ds_financial_email: '',
    nm_key_contact_person: '',

    cd_cep: '',
    ds_address: '',
    vn_number: '',
    ds_neighborhood: '',
    ds_city: '',
    ds_state: '',
    ds_parents: '',

    nm_bank: '',
    vn_agency: '',
    vn_account: '',
    vn_digit: '',
    cd_pix_key: '',

    nm_user: '',
    ds_document: '',
    cd_phone_numbers: '',
    email: '',
    ds_document_social_path: '',
    url_person: '',
    url_council: '',
    accepted: false,
    last_accepted: new Date(),
}

export const validationSchema = Yup.object().shape({
    nm_clinic: Yup.string().required('Campo obrigatório'),
    ds_headquarters_or_branch: Yup.string().required('Campo obrigatório'),
    cd_cnpj: Yup.string().nullable(),
    type_clinic_id: Yup.number().required('Campo obrigatório'),
    specialties: Yup.array().required('Campo obrigatório'),
    subspecialties: Yup.array().required('Campo obrigatório'),

    cd_phone_number: Yup.string().required('Campo obrigatório'),
    ds_administrative_email: Yup.string().required('Campo obrigatório'),
    nm_key_contact_person: Yup.string().required('Campo obrigatório'),

    cd_cep: Yup.string().required('Campo obrigatório'),
    ds_address: Yup.string().required('Campo obrigatório'),
    vn_number: Yup.number().required('Campo obrigatório'),
    ds_neighborhood: Yup.string().required('Campo obrigatório'),
    ds_city: Yup.string().required('Campo obrigatório'),
    ds_state: Yup.string().required('Campo obrigatório'),
    ds_parents: Yup.string().required('Campo obrigatório'),

    nm_bank: Yup.string().required('Campo obrigatório'),
    vn_agency: Yup.number().required('Campo obrigatório').test(
        "Is positive?",
        'Campo deve ser positivo!',
        (value) => {
            if(value !== null && value !== undefined)
                if(value >= 0)
                    return true
                else return false
            return false
        }
    ),
    vn_account: Yup.number().required('Campo obrigatório').test(
        "Is positive?",
        'Campo deve ser positivo!',
        (value) => {
            if(value !== null && value !== undefined)
                if(value >= 0)
                    return true
                else return false
            return false
        }
    ),
    vn_digit: Yup.string().required('Campo obrigatório'),

    nm_user: Yup.string().required('Campo obrigatório!'),
    ds_document: Yup.string().required('Campo obrigatório!'),
    cd_phone_numbers: Yup.string().required('Campo obrigatório!'),
    email: Yup.string().email('Campo inválido!').required('Campo obrigatório!'),
    ds_document_social_path: Yup.string().required('Campo obrigatório!'),
    url_person: Yup.string().required('Campo obrigatório!'),
    url_council: Yup.string().required('Campo obrigatório!'),
    accepted: Yup.boolean().oneOf([true], 'Você precisa aceitar nossos termos de uso e política de privacidade antes de se cadastrar.').required('Campo obrigatório!'),
})
