import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import ChangePassword from './components/ChangePassword'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import { Register } from './components/Register'

export function AuthPage() {
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg-auth.png')})`,
                backgroundSize: 'cover',
            }}
        >
            <div className='d-flex flex-center flex-column flex-column-fluid p-5 p-md-10 pb-lg-20'>
                <Switch>
                    <Route path='/autenticacao/entrar' component={Login} />
                    <Route path='/autenticacao/registrar' component={Register} />
                    <Route path='/autenticacao/esqueceu-senha' component={ForgotPassword} />
                    <Route path='/autenticacao/redefinir-senha' component={ChangePassword} />
                    <Redirect from='/autenticacao' exact={true} to='/autenticacao/entrar' />
                    <Redirect to='/autenticacao/entrar' />
                </Switch>
            </div>
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6 text-white'>
                    <span className='me-2'>{new Date().getFullYear()} &copy;</span>
                    <a
                        href='https://rubyy.com.br/'
                        target='_blank'
                        rel='noreferrer'
                        className='text-white text-hover-primary'
                    >
                        RubyY - O plano é não ter plano
                    </a>
                </div>
            </div>
        </div>
    )
}
