import { useState } from 'react'
import { ShowDocumentModal } from '../../../../pages/terms-and-conditions/components/modals/ShowDocumentModal'
import { FieldInputProps } from 'formik'

interface Props {
    setField: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    getField: <Value = any>(props: any) => FieldInputProps<Value>
}

export default function Terms({setField, getField}: Props) {

    const [accepted, setAccepted] = useState(false)
    const [link, setLink] = useState("")
    const [title, setTitle] = useState("")
    const [isOpenModal, setIsOpenModal] = useState(false)
    return (
        <>
            <div className='card'>
                <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                        <div className='col-lg-4 col-form-label fw-bold fs-6'>
                            Leia os nossos Termos Gerais de Uso:
                        </div>
                        <div className='col-lg-8 fv-row'>
                            <button
                                type='button'
                                className='btn btn-active-color-primary bg-white shadow'
                                title='Link para o documento'
                                onClick={() => {
                                    setIsOpenModal(true);
                                    setLink("https://rubyy.com.br/termos-e-condicoes-gerais-de-uso-plat/");
                                    setTitle("Termos e Condições Gerais de Uso");
                                }}
                            >
                                <i className='bi bi-card-list fs-2'></i>
                                Termos e Condições Gerais de Uso
                            </button>
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <div className='col-lg-4 col-form-label fw-bold fs-6'>
                            Leia os nossos Termos gerais para Clinicas e Profissionais:
                        </div>
                        <div className='col-lg-8 fv-row'>
                            <button
                                type='button'
                                className='btn btn-active-color-primary bg-white shadow'
                                title='Link para o documento'
                                onClick={() => {
                                    setIsOpenModal(true);
                                    setLink("https://rubyy.com.br/termos-e-condicoes-gerais-dos-servicos/");
                                    setTitle("Termos e Condições gerais dos Serviços");
                                }}
                            >
                                <i className='bi bi-card-list fs-2'></i>
                                Termos e Condições gerais dos Serviços
                            </button>
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <div className='col-lg-4 col-form-label fw-bold fs-6'>
                            Leia as nossas Políticas de Privacidade:
                        </div>
                        <div className='col-lg-8 fv-row'>
                            <button
                                type='button'
                                className='btn btn-active-color-primary bg-white shadow'
                                title='Link para o documento'
                                onClick={() => {
                                    setIsOpenModal(true);
                                    setLink("https://rubyy.com.br/politica-de-privacidade-plat/");
                                    setTitle("Políticas de Privacidade");
                                }}
                            >
                                <i className='bi bi-card-list fs-2'></i>
                                Políticas de Privacidade
                            </button>
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                            Aceitar os termos
                        </label>
                        <div className='col-lg-5 fv-row col-form-label fw-bold fs-6'>
                            <div className='form-check'>
                                <input
                                    required
                                    className='form-check-input my-0'
                                    type='checkbox'
                                    onChange={() => { 
                                        
                                        setField('accepted', !accepted) 
                                        setAccepted(!accepted) 
                                        
                                        if(getField("accepted").value){
                                            
                                            setField("last_accepted", new Date())
                                        }
                                    }}
                                    id='accepted'
                                    name='accepted'
                                    checked={accepted}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor='accept-general'
                                >
                                    Eu aceito os Termos Gerais de Usuário.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ShowDocumentModal isOpenCreate={isOpenModal} link={link} title={title} onClose={() => setIsOpenModal(false)} />
        </>
    )
}
