import axios from 'axios'
import { FieldInputProps } from 'formik'
import { useCallback, useEffect, useState} from 'react'
import {Select, TextArea, TextInput, TextInputMask} from '../../../../modules/Form'
import MultiSelect from '../../../../modules/Form/MultiSelect'
import {CNPJMask, CPFMask} from '../../../../modules/masks'
import { SpecialtyRequest, SubSpecialtyRequest, TypeClinicRequest } from '../../models'

interface Props {
    setField: (field: string, value: any) => void
    getField: <Value = any>(props: any) => FieldInputProps<Value>;
}

export default function InfoRegisterClinic({setField, getField}: Props) {
    const [specialty, setSpecialty] = useState<SpecialtyRequest>()
    const [typeClinic, setTypeClinic] = useState<TypeClinicRequest>()
    const [subSpecialty, setSubSpecialty] = useState<SubSpecialtyRequest>()
    const [CMask, setCMask] = useState<(string | RegExp)[]>(CPFMask)
    const [CValue, setCValue] = useState<string>("")

    async function handleKeyPress(value: string, key: string){
        let cleanedValue = value.replace(/\D/g, "");
        if(!(key in ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"])){
            
            return;
        }

        if(cleanedValue.length < 11){
           
            return;
        }

        if(CMask === CNPJMask){
            
            return;
        }
        setCMask(CNPJMask);

        if(value){
            setCMask(CNPJMask);
            
            value = cleanedValue.substring(0,2) + "." + cleanedValue.substring(2, 5) + "." + cleanedValue.substring(5, 8) + "/" + cleanedValue.substring(8,12) + "-" + cleanedValue.substring(12, 14);
           
            setCValue(value)
            await setField('cd_cnpj', value)
            
        }
    }

    async function handleMask(value: string){
        let cleanedValue = value.replace(/\D/g, "");
        if(cleanedValue && cleanedValue.length < 11){
            setCMask(CPFMask)        
            
            setCValue(value)
            await setField('cd_cnpj', value)
            return
        }
        if(cleanedValue && cleanedValue.length === 11){
            
            setCMask(CPFMask);
            value = cleanedValue.substring(0,3) + "." + cleanedValue.substring(3, 6) + "." + cleanedValue.substring(6, 9) + "-" + cleanedValue.substring(9,11);
            setCValue(value)
            await setField('cd_cnpj', value)
            
            return;
        }
        setCMask(CNPJMask);
        value = cleanedValue.substring(0,2) + "." + cleanedValue.substring(2, 5) + "." + cleanedValue.substring(5, 8) + "/" + cleanedValue.substring(8,12) + "-" + cleanedValue.substring(12, 14);
        await setField('cd_cnpj', value)
        setCMask(CNPJMask);
        setCValue(value)
        
            
    }

    const getSpecialty = useCallback(async () => {
        const {data} = await axios.get<SpecialtyRequest>('/external-functions/get-specialty')
        setSpecialty(data)
    }, [])

    const getTypeClinic = useCallback(async () => {
        const {data} = await axios.get<TypeClinicRequest>('/external-functions/get-type-clinics')
        setTypeClinic(data)
    }, [])

    async function getSubSpecialty(values: number[]) {
        if (values.length === 0) {
            setSubSpecialty(undefined)
            return
        }
        const {data} = await axios.post<SubSpecialtyRequest>('/external-functions/get-sub-specialty', {
            ids: values,
        })
        setSubSpecialty(data)
    }

    useEffect(() => {
        let value = getField("cd_cnpj").value;
        setField("cd_cnpj", value);
        if(value.length > 14)
            setCMask(CNPJMask);
        setCValue(value);
        getSpecialty()
        getTypeClinic()
    }, [getTypeClinic, getSpecialty, getField, setField])

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12 col-md-7 mb-5'>
                        <TextInput
                            name='nm_clinic'
                            label='Nome/Razão Social'
                            placeholder='Nome'
                            required
                        />
                    </div>
                    <div className='col-12 col-md-5 mb-5'>
                        <TextInput name='nm_fantasy' label='Nome Fantasia' placeholder='Nome' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4 mb-5'>
                        <Select
                            name='ds_headquarters_or_branch'
                            label='Matriz ou Filial'
                            required
                        >
                            <option value='Matriz'>Matriz</option>
                            <option value='Filial'>Filial</option>
                        </Select>
                    </div>
                    <div className='col-12 col-md-4 mb-5'>
                        <TextInputMask
                            mask={CMask}
                            value={CValue}
                            name='cd_cnpj'
                            label='CPF/CNPJ'
                            placeholder='CPF/CNPJ'
                            onChange={async (e) => {
                                await setField('cd_cnpj', e.target.value)
                                setCValue(e.target.value)
                                handleMask(e.target.value)
                            } }
                            onKeyPress={async (e) => {
                                await setField('cd_cnpj', (e.target as HTMLInputElement).value)
                                setCValue((e.target as HTMLInputElement).value)
                                handleKeyPress((e.target as HTMLInputElement).value, e.key)
                            }}
                        />
                    </div>
                    <div className='col-12 col-md-4 mb-5'>
                        <TextInput
                            name='cd_insc_state'
                            label='Insc. Estadual'
                            placeholder='*********'
                            maxLength={9}
                            type='number'
                            max={999999999}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4 mb-5'>
                        <Select name='type_clinic_id' label='Tipo de estabelecimento' required>
                            {typeClinic?.data.select.map((item) => (
                                <option key={`type-${item.id}`} value={item.id}>
                                    {item.nm_type_clinic}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div className='col-12 col-md-8 mb-5'>
                        <MultiSelect
                            name='specialties'
                            label='Especialidades'
                            options={specialty?.data.select.map((item) => ({
                                label: item.nm_specialty,
                                value: item.id,
                            }))}
                            onChange={(value) => {
                                getSubSpecialty(value.map((item) => item.value))
                            }}
                            required
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-12 mb-5'>
                        <MultiSelect
                            name='subspecialties'
                            label='Subespecialidades'
                            options={subSpecialty?.data.select.map((item) => ({
                                label: item.nm_subspecialty,
                                value: item.id,
                            }))}
                            required
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <TextArea
                            name='ds_business_segment'
                            label='Segmento de atuação'
                            placeholder='Segmento de atuação'
                            rows={3}
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}