import {lazy} from 'react'
import {Route, Switch} from 'react-router-dom'

export function SupportRouter() {
    /*Rotas para Historico de Atividades*/
    const DatatableMyTicket = lazy(() => import('../../pages/support/tickets/DatatableMyTicket'))
    const DatatableAllTicket = lazy(() => import('../../pages/support/tickets/DatatableAllTicket'))
    const ShowTicket = lazy(() => import('../../pages/support/tickets/ShowTicket'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path='/suporte/tickets/meus' component={DatatableMyTicket} />
            <Route path='/suporte/tickets/clinica' component={DatatableAllTicket} />
            <Route path='/suporte/tickets/:id' component={ShowTicket} />
        </Switch>
    )
}
