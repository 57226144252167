import {TextInput, TextInputMask} from '../../../../modules/Form'
import {phoneMask} from '../../../../modules/masks'

export default function ContactRegisterClinic() {
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput
                                label='Pessoa Chave para Contato'
                                name='nm_key_contact_person'
                                placeholder='Nome'
                                required
                            />
                        </div>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInputMask
                                name='cd_phone_number'
                                label='Telefone'
                                placeholder='(99) 99999-9999'
                                mask={phoneMask}
                                required
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput
                                name='ds_administrative_email'
                                label='E-mail Administrativo'
                                placeholder='email@email.com'
                                type='email'
                                required
                            />
                        </div>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput
                                name='ds_financial_email'
                                label='E-mail Financeiro'
                                placeholder='email@email.com'
                                type='email'
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput name='nm_site' label='Site' placeholder='www.site.com.br' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
