import { DocumentInput } from '../../../Form'

export default function DocumentationRegisterClinic() {
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-12 mb-5'>
                            <DocumentInput
                                label='Contrato social'
                                name='ds_document_social_path'
                                required
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12 mb-5'>
                            <DocumentInput
                                label='Identidade do sócio e/ou procurador representante'
                                name='url_person'
                                required
                            />
                        </div>
                    </div>
                    <div className='row'>
                    <div className='col-lg-12 mb-5'>
                            <DocumentInput
                                label='Registro no conselho'
                                name='url_council'
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
