import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import BillingRouter from './internal/BillingRouter'
import {DashboardRouter} from './internal/DashboardRouter'
import ManagementsRouter from './internal/ManagementsRouter'
import {ProviderRouter} from './internal/ProviderRouter'
import SettingsRouter from './internal/SettingsRouter'
import {SupportRouter} from './internal/SupportRouter'

export function PrivateRoutes() {
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

    const SchedulePage = lazy(() => import('../pages/schedule/SchedulePage'))

    const ConsultPage = lazy(() => import('../pages/consults/ListConsults'))
    const ConsultProfilePage = lazy(() => import('../pages/consults/ProfileConsult'))

    const CouponsPage = lazy(() => import('../pages/consults/coupons/ListCoupons'))
    const CouponsProfile = lazy(() => import('../pages/consults/coupons/ProfileCoupon'))
    const CouponRegister = lazy(() => import('../pages/consults/coupons/RegisterCoupon'))
    const ProfileClinic = lazy(() => import('../pages/providers/clinics/ProfileClinic'))
    const TermsAndConditions = lazy(() => import('../pages/terms-and-conditions/TermsAndConditions'))

    const AuditPage = lazy(() => import('../pages/audit/ListAudit'))

    return (
        <Suspense fallback={<FallbackView />}>
            <Switch>
                <Route path='/account' component={AccountPage} />
                <Route path='/estabelecimento' component={ProfileClinic} />
                <Route path='/termos-e-condicoes' component={TermsAndConditions} />
                <Route path='/agendas' component={SchedulePage} />

                <Route path='/consultas/:id' component={ConsultProfilePage} />
                <Route path='/consultas' component={ConsultPage} />

                <Route path='/cupons/cadastro' component={CouponRegister} />
                <Route path='/cupons/:id' component={CouponsProfile} />
                <Route path='/cupons' component={CouponsPage} />

                <Route path='/prestadores' component={ProviderRouter} />

                <Route path='/gestao' component={ManagementsRouter} />

                <Route path='/financeiro' component={BillingRouter} />

                <Route path='/auditoria' component={AuditPage} />

                <Route path='/suporte' component={SupportRouter} />

                <Route path='/configuracoes' component={SettingsRouter} />


                <Route path='/' component={DashboardRouter} />

                <Redirect from='/autenticacao/entrar' to='/dashboard/administrativo' />

                <Redirect to='/error/404' />
            </Switch>
        </Suspense>
    )
}
