import {Field, FieldProps, FormikProps} from 'formik'
import React from 'react'
import {parseToNull} from '../utils/parseToNull'
import SelectMulti from 'react-select'
import makeAnimated from 'react-select/animated'

interface multiProps {
    options?: {value:string|number, label:string}[]
    defaultValue?: {value:string, label:string}
    label?: string
    name: string
    required?: boolean
    onChange?: (value: {value:string, label:string}[]) => void
    placeholder?: string
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    name: string
    label?: string
    value?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onChangeParsed?: (value: string | null) => void
    onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void
    required?: boolean
    disabled?: boolean
    placeholder?: string
    formik?: FormikProps<any>
    children: React.ReactNode
}

export function Select({
    name,
    label,
    value = '',
    onChange,
    onChangeParsed,
    onBlur,
    required = false,
    children,
    placeholder,
    formik,
    disabled = false,
    ...props
}: Props) {
    return (
        <>
            {label && (
                <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                    {label}
                    {required && <span className='text-danger'>*</span>}
                </label>
            )}
            <Field name={name}>
                {({form}: FieldProps) => (
                    <>
                        <select
                            className='form-select form-select-solid form-select-lg'
                            id={name}
                            name={name}
                            disabled={disabled}
                            value={value || form.values[name] || ''}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                onChange
                                    ? onChange(event)
                                    : onChangeParsed
                                    ? onChangeParsed(parseToNull(event.target.value))
                                    : form.setFieldValue(name, parseToNull(event.target.value))
                            }}
                            onBlur={onBlur || form?.handleBlur}
                            {...props}
                        >
                            <option value={-1}>{placeholder || 'Selecione...'}</option>
                            {children}
                        </select>
                        {form.touched[name] && form.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form?.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Field>
        </>
    )
}

export function SearchSelect({
    name,
    options,
    defaultValue,
    label,
    required,
    onChange,
    placeholder,
}: multiProps) {
    const animatedComponents = makeAnimated()

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#f5f8fa',
            border: 'none',
            padding: '4px 0',
            margin: '0',
            color: '#8e1537',
            boxShadow: 'none',
        }),
        multiValue: (styles: any, {data}: any) => ({
            ...styles,
            backgroundColor: '#8e1537',
            border: 'none',
            padding: '2px 0',
            color: '#fff',
            borderRadius: '8px',
        }),
        multiValueLabel: (styles: any, {data}: any) => ({
            ...styles,
            color: '#fff',
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            color: '#8e1537',
        }),
        clearIndicator: (styles: any) => ({
            ...styles,
            color: '#8e1537',
        }),
    }

    return (
        <div>
            <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            <Field name={name}>
                {({form}: FieldProps) => (
                    <>
                        <SelectMulti
                            id={name}
                            styles={customStyles}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            defaultValue={defaultValue}
                            value={form.values[name]}
                            options={options}
                            onChange={(e) => {
                                form.setFieldValue(name, e)
                                onChange?.(e)
                            }}
                            onBlur={form.handleBlur}
                            placeholder={
                                <span className='fs-6 fw-bold text-gray-700'>{placeholder ? placeholder : "Selecione..."}</span>
                            }
                            noOptionsMessage={() => 'Nenhum item disponível'}
                        />
                        {form?.touched[name] && form?.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form?.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Field>
        </div>
    )
}
