import {Field, FieldProps} from 'formik'
import SelectMulti from 'react-select'
import makeAnimated from 'react-select/animated'

interface MultiSelectOption {
    label: string
    value: number
}
interface Props {
    options?: MultiSelectOption[]
    defaultValue?: MultiSelectOption
    label?: string
    name: string
    required?: boolean
    onChange?: (value: MultiSelectOption[]) => void
}
export default function MultiSelect({
    name,
    options,
    defaultValue,
    label,
    required,
    onChange,
}: Props) {
    const animatedComponents = makeAnimated()

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#f5f8fa',
            border: 'none',
            padding: '4px 0',
            margin: '0',
            color: '#8e1537',
            boxShadow: 'none',
        }),
        multiValue: (styles: any, {data}: any) => ({
            ...styles,
            backgroundColor: '#8e1537',
            border: 'none',
            padding: '2px 0',
            color: '#fff',
            borderRadius: '8px',
        }),
        multiValueLabel: (styles: any, {data}: any) => ({
            ...styles,
            color: '#fff',
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles,
            color: '#8e1537',
        }),
        clearIndicator: (styles: any) => ({
            ...styles,
            color: '#8e1537',
        }),
    }

    return (
        <div>
            <label htmlFor={name} className='form-label fs-6 fw-bolder'>
                {label}
                {required && <span className='text-danger'>*</span>}
            </label>
            <Field name={name}>
                {({form}: FieldProps) => (
                    <>
                        <SelectMulti
                            id={name}
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={defaultValue}
                            isMulti
                            value={form.values[name]}
                            options={options}
                            onChange={(e) => {
                                form.setFieldValue(name, e)
                                onChange?.(e)
                            }}
                            onBlur={form.handleBlur}
                            placeholder={
                                <span className='fs-6 fw-bold text-gray-700'>Selecione...</span>
                            }
                            noOptionsMessage={() => 'Nenhum item disponível'}
                        />
                        {form?.touched[name] && form?.errors[name] && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>
                                        {form?.errors[name]}
                                    </span>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Field>
        </div>
    )
}
