import {Select, TextInput, TextInputMask} from '../../../../modules/Form'
import {CEPMask} from '../../../../modules/masks'
import { states } from '../../models/States'

export default function AddressRegisterClinic() {
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-12 col-md-4 mb-5'>
                            <TextInputMask
                                mask={CEPMask}
                                name='cd_cep'
                                label='CEP'
                                placeholder='99999-999'
                                required
                            />
                        </div>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput
                                name='ds_address'
                                label='Logradouro'
                                placeholder='Logradouro'
                                required
                            />
                        </div>
                        <div className='col-12 col-md-2 mb-5'>
                            <TextInput type='number' name='vn_number' label='N°' placeholder='999' required />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput name='ds_complement' label='Complemento' placeholder='Complemento' />
                        </div>
                        <div className='col-12 col-md-6 mb-5'>
                            <TextInput
                                name='ds_neighborhood'
                                label='Bairro'
                                placeholder='Bairro'
                                required
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <TextInput name='ds_city' label='Cidade' placeholder='Cidade' required />
                        </div>
                        <div className='col-12 col-md-2'>
                            <Select name='ds_state' label='Estado' required>
                                {states.map((item) => (
                                    <option key={`state-${item}`} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className='col-12 col-md-4'>
                            <TextInput name='ds_parents' label='País' placeholder='País' required />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
