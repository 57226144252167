import { TextInput, TextInputMask} from '../../../../modules/Form'
import {cellphoneMask, CPFMask} from '../../../../modules/masks'

export default function UserRegisterClinic() {
    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-4 mb-5'>
                            <TextInput
                                label='Nome'
                                name='nm_user'
                                placeholder='Ex: Luiz Felipe'
                                required
                            />
                        </div>
                        <div className='col-lg-4 mb-5'>
                            <TextInput
                                label='E-mail'
                                name='email'
                                placeholder='Ex: luiz@rubyy.com.br'
                                required
                            />
                        </div>
                        <div className='col-lg-4 mb-5'>
                            <TextInputMask
                                label='CPF'
                                name='ds_document'
                                placeholder='___.___.___-__'
                                required
                                mask={CPFMask}
                            />
                        </div>
                        <div className='col-lg-4 mb-5'>
                            <TextInputMask
                                label='Telefone'
                                name='cd_phone_numbers'
                                placeholder='(__) _____-____'
                                required
                                mask={cellphoneMask}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
