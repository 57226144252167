import {ButtonGoBack} from './ButtonGoBack'

interface Props {
    title: string
    children?: React.ReactNode
    goBack?: boolean
    description?: string
}
export function CardHeader({title, children, goBack = true, description = ''}: Props) {
    return (
        <div className='card-header'>
            <div className='card-title align-items-start flex-column'>
                <span className='card-label'>{title}</span>
                <span className='d-none d-sm-block text-muted fw-bold fs-6'>{description}</span>
            </div>
            <div className='card-toolbar'>
                {children}
                {goBack && <ButtonGoBack />}
            </div>
        </div>
    )
}
