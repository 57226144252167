import axios from 'axios'
import {Form, Formik} from 'formik'
import {useState} from 'react'
import Alert from './Alert'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import ButtonAuthCancel from '../../../../_metronic/layout/components/buttons/ButtonAuthCancel'
import InternalNavigation from '../../internalNavigation/InternalNavigation'
import { initialValues, validationSchema } from '../models/RegisterInitialValues'
import { HeaderTextWidget } from '../widgets/HeaderTextWidget'
import AddressRegisterClinic from './subpages/AddressRegisterClinic'
import BankRegisterClinic from './subpages/BankRegisterClinic'
import ContactRegisterClinic from './subpages/ContactRegisterClinic'
import InfoRegisterClinic from './subpages/InfoRegisterClinic'
import UserRegisterClinic from './subpages/UserRegisterClinic'
import DocumentationRegisterClinic from './subpages/DocumentationRegisterClinic'
import Terms from './subpages/Terms'

export function Register() {
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const history = useHistory()

    async function handleSubmit(values: typeof initialValues) {
        try {
            setLoading(true)
            const {data} =  await axios.post('/auth/register', values)
            setAlertMessage(data.message)
            setOpenAlert(true);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='w-lg-1000px bg-white rounded shadow-sm p-10 mx-auto'>
            <div className='text-center mb-5'>
                <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/auth/logo-auth.png')}
                    className='h-45px'
                />
            </div>
            <HeaderTextWidget>
                Bem-vindo(a) a RubyY Estabelecimento!
                <br />
                O plano é não ter plano.
            </HeaderTextWidget>
            <div className='mt-5'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {({handleSubmit, errors, setFieldValue, getFieldProps}) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                if (Object.keys(errors).length) {
                                    toast.error('Preencha todos os campos obrigatórios')
                                    return 
                                }
                                handleSubmit()
                            }}
                        >
                            <InternalNavigation
                                labels={[
                                    'Informações', 
                                    'Documentação',
                                    'Contato', 
                                    'Endereço', 
                                    'Dados Bancários',
                                    'Usuário',
                                    'Termos e Condições',
                                ]}
                                pages={{
                                    info: <InfoRegisterClinic setField={setFieldValue} getField={getFieldProps}/>,
                                    docs: <DocumentationRegisterClinic />,
                                    contact: <ContactRegisterClinic />,
                                    address: <AddressRegisterClinic />,
                                    bank: <BankRegisterClinic setField={setFieldValue} />,
                                    user: <UserRegisterClinic />,
                                    terms: <Terms setField={setFieldValue} getField={getFieldProps}/>,
                                }}
                                titleSubmit='Cadastrar'
                                loading={loading}
                                buttonPrev
                                buttonNext
                            />
                        </Form>
                    )}
                </Formik>
               
            </div>
            <div className='text-center'>
                <div className='my-5'>
                    <span className='fw-bolder'>Já faz parte? Acesse seu estabelecimento!</span>
                </div>
                <ButtonAuthCancel 
                    buttonTitle='Acessar plataforma'
                    buttonUrl={'/autenticacao/entrar'}
                />
            </div>
            <Alert
                onClose={() => {
                setOpenAlert(false);
                    history.push("/");
                }}
                title={alertMessage}
                open={openAlert}
            />
        </div>
    )
}
