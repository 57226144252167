import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {actions} from '../../app/modules/auth'

export default function setupAxios(axios: any, store: any) {
    axios.interceptors.request.use(
        (config: any) => {
            config.baseURL = process.env.REACT_APP_API_URL
            const {
                auth: {token, clinic_id},
            } = store.getState()
            if (token) {
                config.headers.Authorization = `Bearer ${token}`
                if (clinic_id) {
                    config.headers['Clinic-Id'] = clinic_id
                }
            }
            return config
        },
        (err: any) => {
            return Promise.reject(err)
        }
    )
    axios.interceptors.response.use(
        (config: any) => config,
        async (err: any) => {
            if (err.response?.data?.message) {
                toast.error(err.response.data.message)
            } else if (err.response?.status) {
                if (err.response.status === 403) {
                    const dispatch = useDispatch()
                    try {
                        const {
                            auth: {refresh_token, refresh_tokenFunction, getUserByToken},
                        } = store.getState()
                        const {data} = await refresh_tokenFunction(String(refresh_token))
                        dispatch(
                            actions.refresh_token(data.token, data.refresh_token, data.clinic_id)
                        )
                        const userData = await getUserByToken()
                        dispatch(actions.fulfillUser(userData.data.data.user))
                    } catch (error) {
                        console.error(error)
                        dispatch(actions.logout())
                    }
                }
                toast.error(`${err.response?.status} - ${err.response?.statusText}`)
            } else {
                toast.error('Erro no servidor!')
            }
            return Promise.reject(err)
        }
    )
}
