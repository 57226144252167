import './iframes.scss'
import { Modal, ResponsiveEmbed } from 'react-bootstrap-v5'


interface Props {
    onClose: () => void
    isOpenCreate: boolean
    link: string
    title: string
}

export function ShowDocumentModal({onClose, isOpenCreate, link, title}: Props) {
  
    return (
        <Modal
            onHide={() => onClose()}
            show={isOpenCreate}
            size='lg'
            aria-labelledby='contained-modal-title-center'
            centered
        >
            <Modal.Header>
                <Modal.Title id='contained-modal-title-center'>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ResponsiveEmbed aspectRatio="21by9" className="hideHeader">
                    <embed className='w-100 h-100' title="Políticas de Privacidade" src={link} />
                </ResponsiveEmbed>
            </Modal.Body>
        </Modal>
    )
}
