import {lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

export function DashboardRouter() {
    /*Rotas para Historico de Atividades*/
    const DashboardAdministrative = lazy(
        () => import('../../pages/dashboard/DashboardAdministrative')
    )

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}

            <Route path='/dashboard/administrativo' component={DashboardAdministrative} />
            <Redirect exact from='/' to='/dashboard/administrativo' />
        </Switch>
    )
}
